// @import '~nprogress/nprogress.css';
@import "~alertifyjs/build/css/alertify.min.css";
@import "~alertifyjs/build/css/themes/semantic.min.css";
@import "~bootstrap3/dist/css/bootstrap.min.css";

@font-face {
  font-family: Main;
  font-display: auto;
  src: url("../assets/fonts/33535gillsansmt.woff");
}

body {
  background-color: #fff;
  font-family: Main, sans-serif;
  font-size: 14px;
  color: #050505;
}
.hideOver {
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  #scroll-container {
    position: absolute;
    overflow: hidden;
    backface-visibility: hidden;
    transform-style: preserve-3d;
  }
}
html {
  //    / scroll-behavior: smooth;
}
* {
  box-sizing: border-box;
}
.juicer-feed.polaroid li.feed-item.j-twitter .j-message {
  font-family: sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.content {
  min-height: 50vh;
  position: relative;
  top: 100px;
}
.btn {
  font-weight: bold;
  min-width: 200px;
}
.btn-primary {
  border-radius: 100px;
  background-color: #b3be35;
  border: 2px solid #b3be35 !important;
  color: #fff;
  &:active,
  &:focus,
  &:hover {
    background-color: #fff;
    border: 2px solid #b3be35 !important;
    color: #050505;
  }
}
.btn-default {
  border-radius: 100px;
  border: 2px solid #b3be35 !important;
  color: #050505;
  &:active,
  &:focus,
  &:hover {
    background-color: #fff;
    border: 2px solid #b3be35 !important;
    color: #050505;
  }
}
.btn-trans {
  border-radius: 100px;
  border: 2px solid #fff !important;
  color: #fff;
  background-color: rgba(255, 255, 255, 0);
  &:active,
  &:focus,
  &:hover {
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid #fff !important;
    color: #fff;
  }
}
.btn-secondary {
  border-radius: 100px;
  background-color: rgba(2, 73, 48, 1);
  border: 2px solid rgba(2, 73, 48, 1);
  color: #fff;
  &:active,
  &:focus,
  &:hover {
    background-color: #fff;
    border: 2px solid rgba(2, 73, 48, 1) !important;
    color: rgba(2, 73, 48, 1);
  }
}
.btn-dark {
  border-radius: 100px;
  background-color: rgba(4, 57, 42, 1);
  border: 2px solid rgba(4, 57, 42, 1);
  color: #fff;
  &:active,
  &:focus,
  &:hover {
    background-color: #fff;
    border: 2px solid rgba(4, 57, 42, 1) !important;
    color: #050505;
  }
}

.btn-dark-2 {
  border-radius: 100px;
  background-color: #7e4e78;
  border: 2px solid #7e4e78;
  color: #fff;
  &:active,
  &:focus,
  &:hover {
    background-color: #fff;
    border: 2px solid #7e4e78 !important;
    color: #050505;
  }
}
.spacer {
  display: block;
  height: 15px;
  display: block;
  height: 15px;
  position: relative;
  clear: both;
}
.on-mobile {
  display: none !important;
}
button,
.btn,
a {
  outline: none !important;
}
.text-white {
  color: #fff;
}
.btn-text {
  background-color: unset;
  border: unset;
  text-decoration: underline;
}

.modal-content {
  border-radius: 0px;
}
.modal-body {
  overflow: auto;
}
.label-info {
  background-color: #aeb400;
}
.loading-btn {
  background-image: url("../assets/loading.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40px;
  background-color: #fff !important;
  width: 100%;
  min-width: 100px;
  min-height: 60px;
}
.loading {
  background-image: url("../assets/loading.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50px;
  height: 50vh;
  position: relative;
  background-color: #fff;
  width: 100%;
}
.loading span {
  font-size: 14px;
  position: absolute;
  display: block;
  bottom: 10vh;
  width: 100%;
  text-align: center;
}
.pagination {
  li {
    &.active {
      a {
        background-color: #b3be35;
        border: 1px solid #b3be35;
        &:hover,
        &:focus,
        &:focus {
          background-color: #b3be35;
          border: 1px solid #b3be35;
        }
      }
    }
    a {
      color: #050505;
    }
  }
}
.alertify-notifier {
  .ajs-message {
    width: 317px;
    &.ajs-success {
      color: #fff !important;
      text-shadow: unset;
      font-weight: 400;
      font-size: 18px;
      background: #0caa51 !important;
    }
    &.ajs-error {
      color: #fff !important;
      text-shadow: unset;
      font-weight: 400;
      font-size: 18px;
      background: #f45e2f !important;
    }
    &.ajs-message {
      color: #050505;
      text-shadow: unset;
      font-weight: 400;
      font-size: 18px;
      background: #fff;
    }
  }
}
.chat {
  a {
    &:hover {
      cursor: pointer;
    }
  }
}
.stories {
  margin-top: 30px;
  h3 {
    color: #04392a;
    font-size: 36px;
    font-weight: 600;
    line-height: 50px;
    text-align: center;
    &::after {
      content: "";
      height: 2px;
      width: 100px;
      position: absolute;
      bottom: 0;
      left: 45%;
      border-bottom: 3px solid #04392a;
    }
  }
  .other {
    .item {
      background-position: center top;
      background-repeat: no-repeat;
      background-size: cover;
      height: 260px;
      margin-top: 15px;
      margin-bottom: 15px;
      display: block;
      position: relative;
      &::after {
        position: absolute;
        top: 0;
        height: 100%;
        content: "";
        width: 100%;
      }
    }
    .caption {
      position: absolute;
      bottom: 0;
      left: 0;
      height: auto;
      width: 100%;
      color: #fff;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      padding: 15px;
      h6 {
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        line-height: 19px;
        display: block;
        position: relative;
        padding-bottom: 5px;
        &::after {
          position: absolute;
          bottom: 0;
          content: "";
          border-bottom: 2px solid rgba(179, 190, 53, 1);
          width: 96px;
          left: 0%;
        }
      }
      h4 {
        color: rgba(255, 255, 255, 1);
        font-size: 27px;
        font-weight: 600;
        line-height: 34px;
        padding-bottom: 15px;
        position: relative;
        &::after {
          position: absolute;
          bottom: 0;
          content: "";
          border-bottom: 2px solid rgba(179, 190, 53, 1);
          width: 214px;
          left: 0%;
        }
      }
      span {
        color: rgba(255, 255, 255, 1);
        font-size: 18px;
        line-height: 21px;
      }
    }
  }
  .main {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 549px;
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
    position: relative;
    &::after {
      position: absolute;
      top: 0;
      height: 100%;
      content: "";
      width: 100%;
    }
    .caption {
      position: absolute;
      bottom: 0;
      left: 0;
      height: auto;
      width: 100%;
      color: #fff;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      padding: 15px;
      h6 {
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        line-height: 19px;
        display: block;
        position: relative;
        padding-bottom: 5px;
        &::after {
          position: absolute;
          bottom: 0;
          content: "";
          border-bottom: 2px solid rgba(179, 190, 53, 1);
          width: 96px;
          left: 0%;
        }
      }
      h4 {
        color: rgba(255, 255, 255, 1);
        font-size: 27px;
        font-weight: 600;
        line-height: 34px;
        padding-bottom: 15px;
        position: relative;
        &::after {
          position: absolute;
          bottom: 0;
          content: "";
          border-bottom: 2px solid rgba(179, 190, 53, 1);
          width: 214px;
          left: 0%;
        }
      }
      span {
        color: rgba(255, 255, 255, 1);
        font-size: 18px;
        line-height: 21px;
      }
    }
  }
}
.text-center {
  text-align: center !important;
}

.alertify-notifier.ajs-right {
  right: 40px;
}
.container-custom {
  width: 80% !important;
  margin: 30px auto !important;
}
.banner-custom.default {
  position: relative;
  &::after {
    background-color: rgba(4, 57, 42, 0.5);
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    content: "";
    z-index: 20;
  }
  .container-custom {
    z-index: 30;
    position: relative;
  }
}
.banner-custom {
  min-height: 30vh !important;
  background-color: #04392a;
  background-position: center;
  background-repeat: no-repeat;
  overflow: auto;
  background-size: cover;
  margin-top: 0px;
  padding-bottom: 30px;
  &.inverse {
    background-color: #fff;
    h1 {
      color: #04392a;
      &::after {
        border-bottom: 2px solid #04392a;
      }
    }
    a {
      color: #04392a;
    }
  }
  h1 {
    margin-top: 2%;
    color: #fff;
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 0.75px;
    line-height: 55px;
    text-align: center;
    margin-bottom: 15px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      width: 200px;
      left: 42%;
      height: 2px;
      border-bottom: 2px solid #ffffff;
    }
    span {
      display: inline-block;
    }
  }
  span {
    color: #fff;
    display: block;
    text-align: center;
    a {
      color: #fff;
      color: #ffffff;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
    }
  }
}

.banner-custom-2 {
  min-height: 40vh !important;
  background-color: #b3be35;
  background-position: center;
  background-repeat: no-repeat;
  overflow: auto;
  background-size: cover;
  margin-top: 0px;
  padding-bottom: 30px;
  h1 {
    margin-top: 2%;
    color: #04392a;
    font-size: 60px;
    font-weight: bold;
    letter-spacing: 0.75px;
    line-height: 55px;
    text-align: left;
    margin-bottom: 15px;
    position: relative;
  }
  p {
    font-size: 25px;
    color: #04392a;
  }
  span {
    color: #fff;
    font-size: 30px;
    margin-top: 50px;
    display: block;
  }
}
.steps {
  .content-container {
    background-color: rgba(0, 0, 0, 0.03);
    padding: 10px;
  }
  h3 {
    font-size: 35px;
    font-weight: bold;
    color: #04392a;
  }
  h4 {
    font-size: 20px;
    font-weight: bold;
    color: #04392a;
    height: 50px;
  }
  p {
    color: #050505;
    font-size: 16px;
  }
  .step1 {
    background-color: rgba(0, 0, 0, 0.03);

    border-left: 5px solid #fcd116;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .step2 {
    background-color: rgba(0, 0, 0, 0.03);
    border-left: 5px solid #aeb400;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .step3 {
    background-color: rgba(0, 0, 0, 0.03);
    border-left: 5px solid #024930;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
.connection-requirements {
  h3 {
    color: #04392a;
    font-size: 50px;
    font-weight: bold;
  }
  li {
    line-height: 26px;
    margin-bottom: 20px;
    list-style-image: url("../assets/bullet_line.png");
    font-size: 20px;
  }
}
.charges {
  h3 {
    color: #04392a;
    font-size: 50px;
    font-weight: bold;
  }
  span {
    font-size: 18px;
  }
  h5 {
    font-size: 20px;
    font-weight: bold;
    color: #04392a;
    margin-top: 20px;
  }
  table:nth-child(2) {
    tr:first-child {
      background-color: #aeb400 !important;
      color: #f5f5f5;
    }
  }
  table:nth-child(4) {
    tr:first-child {
      background-color: #024930 !important;
      color: #f5f5f5;
    }
  }
  table:nth-child(6) {
    tr:first-child {
      background-color: #161514 !important;
      color: #f5f5f5;
    }
  }
  table {
    height: auto !important;
    width: 100% !important;

    // tr:first-child {
    //   background-color: #04392a !important;
    //   color: #f5f5f5;
    // }
    tbody {
      tr {
        td {
          border: 1px solid #050505;
          padding: 5px;
        }
      }
    }
  }
}
.FactsFigures {
  background-image: url("../assets/default_banner_blur.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: auto;
  width: 100%;
  padding: 50px 0px;
  h3 {
    color: #ffffff;
    font-size: 25px;
    font-weight: 600;
    line-height: 50px;
    text-align: center;
  }
  span {
    color: #ffffff;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
  }
  .flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    align-content: space-around;
    width: 100%;
    height: 500px;
    position: relative;
    .item {
      display: flex;
      justify-content: start;
      width: 45%;
      height: 100px;
      background-color: #fcd116;
      margin: 15px;
      padding: 15px;
      img {
        height: 100%;
        width: 54.17px;
        flex-shrink: 2;
        margin: 0 15px;
      }
      span {
        color: #ffffff;
        font-size: 18px;
        font-weight: 600;
        flex-shrink: 1;
        margin: 10px 15px;
        line-height: 25px;
        display: block;
        text-align: left;
      }
      h4 {
        color: #ffffff;
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        flex-shrink: 2;
        margin: 0 15px;
      }
    }
  }
}
.outage_alerts {
  h3 {
    color: #04392a;
    font-size: 36px;
    font-weight: 600;
    line-height: 43px;
  }
  span {
    height: 19px;
    width: 425px;
    color: #04392a;
    font-size: 16px;
    line-height: 19px;
  }
  h4 {
    color: #04392a;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    text-transform: uppercase;
    margin: 43px 0 20px 0;
  }
  .list {
    background-color: rgba(216, 216, 216, 0.29);
    .item {
      border-bottom: 1px solid rgba(151, 151, 151, 0.29);
      overflow: hidden;
      h3 {
        color: #04392a;
        font-size: 34px;
        font-weight: bold;
        line-height: 40px;
        text-align: right;
        width: 100px;
        padding: 15px;
        border-right: 1px solid rgba(151, 151, 151, 0.29);
        float: left;
        display: block;
        margin: 0px;
      }
      span {
        color: #04392a;
        font-size: 16px;
        line-height: 24px;
        display: block;
        padding: 15px;
        float: left;
        width: 500px;
      }
    }
  }
}
.white-card {
  background-color: #fff;
  padding: 30px;
}

.gridTools {
  margin: 30px auto;
  h3 {
    color: #04392a;
    font-size: 36px;
    line-height: 43px;
    text-align: left;
    b {
      font-weight: 600 !important;
    }
  }
  .gridFlex {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    padding: 20px;
    flex-wrap: wrap;
    .item {
      height: 250px;
      width: 25%;
      margin: 0;
      padding: 30px;
      text-align: center;
      position: relative;
      svg {
        fill: #aeb400 !important;
        path {
          fill: #aeb400 !important;
        }
        text {
          fill: #aeb400 !important;
        }
      }
      h5 {
        color: #04392a;
      }
      &:hover {
        text-decoration: none;
        svg {
          fill: rgba(174, 180, 0, 0.714) !important;
          path {
            fill: rgba(174, 180, 0, 0.714) !important;
          }
          text {
            fill: #aeb400 !important;
          }
        }
        h5 {
          color: #161514;
        }
      }
      &.rightBottom {
        &::after {
          position: absolute;
          content: "";
          right: 0;
          bottom: 0;
          width: 100%;
          height: 80%;
          border-bottom: 1px solid rgba(151, 151, 151, 0.2);
          border-right: 1px solid rgba(151, 151, 151, 0.2);
        }
      }
      &.bottom {
        &::after {
          position: absolute;
          content: "";
          right: 0;
          bottom: 0;
          width: 100%;
          border-bottom: 1px solid rgba(151, 151, 151, 0.2);
        }
      }
      &.right {
        &::after {
          position: absolute;
          content: "";
          right: 0;
          top: 0;
          height: 80%;
          border-right: 1px solid rgba(151, 151, 151, 0.2);
        }
      }
      svg {
        height: 80px;
        width: auto;
        margin: 0 auto;
        fill: black;
      }
      h5 {
        color: rgba(5, 5, 5, 1);
        font-size: 24px;
        font-weight: 600;
        line-height: 34px;
        text-align: center;
        padding-bottom: 10px;
        position: relative;
        &::after {
          position: absolute;
          bottom: 0;
          content: "";
          width: 30%;
          left: 35%;
          border-bottom: 1px solid rgba(151, 151, 151, 1);
        }
      }
      span {
        color: rgba(5, 5, 5, 1);
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        display: block;
      }
    }
  }
}
.support-banner {
  background-image: url("../assets/support/support.webp");
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  .faded-side {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 83%,
      rgba(255, 255, 255, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
  .item {
    display: block;
    border-bottom: 1px solid rgba(179, 190, 53, 1);
    padding: 15px;
    h5 {
      color: rgba(5, 5, 5, 1);
      font-size: 26px;
      font-weight: bold;
      line-height: 30px;
      text-align: left !important;
    }
    span {
      color: rgba(140, 132, 132, 1);
      font-size: 18px;
      line-height: 24px;
      text-align: left !important;
    }
  }
  .channels {
    padding: 15px;
    .helpDesk {
      background-color: rgba(179, 190, 53, 1);
      padding: 15px;
      width: 45%;
      margin-right: 2%;
      min-width: 300px;
      color: #fff;
      float: left;
      h5 {
        color: rgba(255, 255, 255, 1);
        font-size: 24px;
        font-weight: bold;
        line-height: 28px;
        text-align: left;
      }
      span {
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        line-height: 19px;
        text-align: left;
        margin-bottom: 15px;
      }
    }
    .liveChat {
      background-color: rgba(179, 190, 53, 1);
      padding: 15px;
      min-width: 300px;
      overflow: auto;
      color: #fff;
      img {
        height: 79px;
        width: auto;
        float: left;
        margin: 15px;
      }
      h5 {
        color: rgba(255, 255, 255, 1);
        font-size: 24px;
        font-weight: bold;
        line-height: 28px;
        text-align: left;
      }
      span {
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        line-height: 19px;
        text-align: left;
        margin-bottom: 15px;
      }
    }
  }
}

.form {
  background-color: #fff;
  min-height: 100vh;
  padding-top: 5vh;
  padding-bottom: 15vh;
  ol.progtrckr {
    li.progtrckr-done {
      color: #5cb85c;
      font-weight: 600;
    }
    li.progtrckr-doing {
      color: #050505;
      font-weight: 600;
      font-size: 14px;
      border-bottom: 4px solid #050505;
      &::before {
        background-color: #050505;
        color: #fff;
        &:hover {
          color: rgba(241, 93, 34, 1);
        }
      }
    }
    li.progtrckr-todo {
      color: rgba(0, 0, 0, 0.6);
      border-bottom: 4px solid rgba(0, 0, 0, 0.6);
      &::before {
        background-color: #04392a;
        border-radius: 50%;
        height: 1.2em;
        content: "";
        width: 1.2em;
        border: 3px solid #fff;
        &:hover {
          color: rgba(241, 93, 34, 1);
        }
      }
    }
  }
  h1 {
    font-size: 36px;
    font-weight: bold;
    line-height: 41px;
    color: #050505;
  }
  a {
    color: #050505;
    text-decoration: underline;

    &:hover {
      color: #050505;
      text-decoration: underline;
    }
  }
  .description {
    height: auto;
    width: 100%;
    color: #050505;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    margin: 47px auto;
    display: block;
  }
  .well {
    display: block;
    text-align: left;
  }
  .input-group,
  .form-group {
    margin-bottom: 50px;
    text-align: left;
    .form-control {
      border-top-right-radius: 40px !important;
      border-bottom-right-radius: 40px !important;
    }
  }
  .input-group-addon {
    background-color: unset !important;
    border: unset !important;
    .info {
      color: #b3be35;
      font-size: 30px;
      cursor: pointer;
      margin-top: 33px;
      display: block;
    }
  }
  label {
    color: #050505;
    display: block;
    text-align: left;
    padding-left: 30px;
    font-size: 22px;
    input {
      height: 25px;
    }
  }
  .radio-inline {
    display: inline;
    padding-left: 50px;
  }
  .form-control {
    height: 80px;
    width: 100%;
    border: 1.5px solid #050505;
    border-radius: 40px !important;
    background-color: #f5f5f5 !important;
    color: #050505 !important;
    font-size: 22px;
    line-height: 25px;
    padding-left: 40px;
    padding-right: 40px;
    position: relative;
    &.info {
      &::after {
      }
    }
    &.has-error {
      border: 2px solid rgba(241, 93, 34, 0.8);
    }
  }
  .btn {
    text-decoration: none;
    &:hover {
      color: #000;
      text-decoration: none;
    }
  }
}
.press-releases {
  h3 {
    color: #04392a;
    font-size: 36px;
    font-weight: 600;
    line-height: 43px;
  }
}
.default-page {
  position: relative;
  h3 {
    color: #04392a;
    font-size: 36px;
    font-weight: 600;
    line-height: 43px;
  }
  h4 {
    color: #050505;
    font-size: 24px;
    font-weight: 500;
    line-height: 33px;
  }
  span {
    color: #050505;
    font-size: 18px;
    line-height: 30px;
  }
  p {
    color: #050505;
    font-size: 18px;
    line-height: 30px;
  }
  ol,
  ul {
    li {
      color: #050505;
      font-size: 18px;
      line-height: 32px;
    }
  }
}
.custom-page {
  h3 {
    color: #050505;
    font-size: 36px;
    font-weight: 600;
    line-height: 43px;
    text-align: center;
  }
  span {
    color: #050505;
    font-size: 18px;
    line-height: 30px;
  }
  p {
    color: #050505;
    font-size: 18px;
    line-height: 30px;
  }
  .h4 {
    color: #050505;
    font-size: 24px;
    font-weight: 500;
    line-height: 33px;
  }
  ol {
    li {
      color: #050505;
      font-size: 18px;
      line-height: 32px;
    }
  }
  table {
    border-color: white;
    thead {
      border-color: #04392a;
      tr {
        background-color: #04392a;

        th {
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          line-height: 22px;
          background-color: #04392a;
          border-color: #fff;
        }
      }
    }
    tbody {
      border-color: white;

      tr {
        td {
          color: rgba(5, 5, 5, 1);
          font-size: 16px;
          line-height: 19px;
          border-color: white !important;
          margin: 15px auto;
          background-color: rgba(27, 155, 214, 0.38);
        }
      }
    }
  }
}
.committee-item {
  .img {
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
.side-section {
  background-color: #aeb400;
  position: sticky;
  top: 0;
  width: 100%;
  overflow: auto;
  padding: 15px;
  h4 {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    line-height: 33px;
  }
  ul {
    padding: 0;
    li {
      list-style: none;
      margin: 15px auto;
      a {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        display: block;
      }
    }
  }
  span {
    display: block;
    color: #fff;
    font-size: 16px;
    margin: 15px auto;
  }
}
.payments-page {
  a.bg {
    background-color: #aeb400;
    color: #fff !important;
    line-height: 14px;
    vertical-align: middle;
    img {
      height: 30px !important;
      width: auto;
    }
  }
  a {
    margin: 15px;
    img {
      height: 30px !important;
      width: auto;
    }
  }
}
.menuBar {
  display: none;
}

@media only screen and (max-width: 1418px) {
  .header li {
    margin-right: 1% !important;
    a {
      font-size: 14px !important;
      svg {
        height: 18px !important;
      }
    }
  }
  .header .navbar-nav {
    width: 85% !important;
  }
  .header .navbar-header {
    width: 15% !important;
  }
  .carousel .carousel-inner .item .slide .carousel-caption {
    width: 50% !important;
  }
  .gridTools,
  .gridServices .gridFlex {
    .item {
      width: 30% !important;
    }
  }
}
//mobile starts here
@media only screen and (max-width: 1048px) {
  .header {
    height: auto;
    .navbar-header {
      width: 50% !important;
      clear: both !important;
      display: block;
      position: absolute;
      top: 0px;
      right: 15px;
      .navbar-brand {
        float: right !important;
        svg {
          height: 60px !important;
        }
      }
    }
    .menuBar {
      display: block !important;
      .nav-item {
        height: 60px;
      }
      svg {
        height: 38px !important;
        display: block;
        fill: #04392a;
      }
      span {
        display: block;
        line-height: 20px;
      }
    }
    .listNav {
      display: none !important;
      &.open {
        display: block !important;
        height: 80vh !important;
        overflow: auto;
      }
    }
    .navbar-nav {
      width: 100% !important;
      clear: both !important;
      height: auto !important;
      display: block;
      li {
        display: block;
        width: 100%;
      }
    }
  }
  .carousel .carousel-inner .item .slide .carousel-caption {
    right: 0 !important;
  }
  .FactsFigures {
    .item {
      h4 {
        font-size: 24px !important;
        line-height: 30px !important;
      }
    }
  }
  .greenCard .profileImage {
    display: none;
  }
  .greenCard {
    min-height: 250px;
  }
}
@media only screen and (max-width: 1200px) {
  .reports_use {
    .publications {
      width: 100% !important;
      margin-left: 0 !important;
    }
    .investorCharts {
      width: 100% !important;
    }
  }
  .investors .numbers .card {
    width: 100% !important;
  }
  .help_support {
    h3 {
      text-align: center !important;
      &::after {
        content: "";
        display: none !important;
        position: relative !important;
      }
    }
    span {
      text-align: center !important;
    }
  }
  .whiteBg .flex a {
    width: 100% !important;
  }
}
@media only screen and (max-width: 1000px) {
  .carousel .carousel-indicators {
    top: 20% !important;
  }
  .carousel .carousel-inner .item .slide .carousel-caption {
    width: 100% !important;
    span {
      font-size: 24px !important;
    }
    a {
      font-size: 30px !important;
    }
  }
  .gridTools,
  .gridServices .gridFlex {
    .item {
      width: 50% !important;
    }
  }
  .container-custom {
    width: 98% !important;
  }
  .outage_alerts {
    .list {
      .item {
        h3 {
          font-size: 24px !important;
          width: 25%;
        }
        span {
          width: 75%;
        }
      }
    }
  }
  .section-custom .content span {
    float: none !important;
    position: relative;
    left: 0 !important;
    padding: 15px;
  }
}

@media only screen and (max-width: 800px) {
  .gridTools,
  .gridServices .gridFlex {
    .item {
      width: 80% !important;
    }
  }
  .section-custom .content .img {
    width: 100% !important;
    clear: both;
    float: none !important;
  }
  .section-custom .content span {
    float: none !important;
    position: relative;
    left: 0 !important;
    padding: 15px !important;
    display: block !important;
  }
  .FactsFigures {
    .flex {
      margin: 0px !important;
      .item {
        width: 100% !important;
        padding: 5px !important;
        margin: 0px !important;
        img {
          width: 35px !important;
        }
      }
    }
  }
  .banner-custom h1::after {
    display: none !important;
  }
  .RelationsPublications {
    .first {
      margin: 0px !important;
      padding: 5px !important;
    }
    .latest {
      padding: 5px !important;
    }
  }
}

@media only screen and (max-width: 780px) {
  .committee-item .img {
    background-position: left !important;
  }
  .footer .columns .play_banner::before {
    display: none;
  }
  .footer .columns .play_banner {
    min-height: 150px !important;
    .links {
      width: 100% !important;
      float: left !important;
      margin: 15px auto !important;
    }
    .description {
      width: 100% !important;
      float: left !important;
      margin: 15px auto !important;
    }
  }
  .tarrif-section .flex .item {
    width: 100% !important;
  }
  .calculate-section .flex .item {
    width: 100% !important;
  }
  .recent-projects .flexed .item {
    width: 100% !important;
  }
  .waterFall .story-item {
    width: 100% !important;
  }
  .story-content p {
    display: block;
    width: 100%;
    a {
      display: block;
      overflow: auto;
    }
  }
  .vacancieFlex .vacancie {
    width: 100% !important;
    min-width: 100% !important;
    margin: 0 !important;
  }
  .flex .item {
    width: 100% !important;
  }
  .banner .greenOverlay {
    padding: 0px !important;
  }
  .banner .greenOverlay svg {
    display: none;
  }
  .sectionInfo span {
    width: 100% !important;
  }
  .btn {
    margin: 15px auto;
    width: 100% !important;
  }
  .checkbox-inline,
  .radio-inline {
    display: block !important;
    width: 100%;
  }
  .ScalableCard .outageItem .details,
  .ScalableCard .outageItem .modal-section {
    width: 100% !important;
    margin: 15px auto;
    margin-left: 0px !important;
  }
}
.row {
  margin-right: 0px !important;
  margin-left: -15px !important;
}
.container-fluid {
  padding: 0 !important;
}
